
import { defineComponent, ref, watch, onMounted, Ref } from "vue";
import useEmitter from "@/composables/Emmiter";
import { useStore } from "vuex";
import FilterBase from "@/layout/header/partials/filters/FilterBase.vue";
import Api from "@/services/Api";
import { ElConfigProvider } from "element-plus";
import ptBr from "element-plus/lib/locale/lang/pt-br";

export interface LojaPlano {
  lojas: number[] | string[] | null;
  dataInicio: any;
  grupo: number | string | null;
  consultor: number | string | null;
}

export default defineComponent({
  name: "FilterCustos",
  components: {
    FilterBase,
    ElConfigProvider,
  },

  setup(props, { emit }) {
    const store = useStore();
    const emitter = useEmitter();
    const closeModal = ref(false);
    const show = ref('lojas');
    const lojas: Ref<any> = ref([]);
    const consultores: Ref<any> = ref([]);
    const grupos: Ref<any> = ref([]);
    const paramsFilter: Ref<LojaPlano> = ref({
        lojas: [],
        dataInicio: new Date(),
        grupo: null,
        consultor: null
    })

    async function getAllStores() {
        try {
            const { data } = await Api.get("getAllStores");
            lojas.value = data.lojas;
        } catch (error) {
        console.log(error);
      }
    }

    async function getAllGroups() {
        try {
            const { data } = await Api.get("getAllStoreGroup");
            grupos.value = data.grupos;
        } catch (error) {
        console.log(error);
      }
    }

    async function getAllConsultor() {
        try {
            const { data } = await Api.get("getAllConsultor");
            consultores.value = data;
        } catch (error) {
        console.log(error);
      }
    }

    async function enviarEmit() {
      await emitter.emit("custosFilter", {
        paramsFilter: paramsFilter.value,
      });
      closeModal.value = !closeModal.value;
    }

    onMounted(() => {
      getAllStores();
      getAllConsultor();
      getAllGroups();
    });

    watch(() => show.value, () => {
      paramsFilter.value = {...paramsFilter.value, lojas: [], grupo: null, consultor: null }
    }, {deep:true}) 

    return { lojas, enviarEmit, ElConfigProvider, ptBr,paramsFilter, consultores, closeModal, grupos, show };
  },
});
